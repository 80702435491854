import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaYoutube, FaTwitter, FaTelegram } from 'react-icons/fa';
//import { FooterContainer, FooterWrapper, SocialMedia, SocialMediaWrapper, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink } from './FooterElements';
import { FooterContainer, FooterWrapper, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, SocialMedia, SocialMediaWrapper, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink } from './FooterElements';
import logo from '../../images/logo-small.png'
const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterLinksContainer>

          <FooterLinksWrapper>
            <FooterLinkItems>
              <a href="https://omniaverse.gitbook.io/omniaverse/about/introduction" target='_blank' rel='noreferrer'>GitBook</a>
            </FooterLinkItems>
            <FooterLinkItems>
            <a href="https://omniaverse.gitbook.io/omniaverse/progress/roadmap" target='_blank' rel='noreferrer'>RoadMap</a>
            </FooterLinkItems>
            <FooterLinkItems>
            <a href="https://scan.theapexchain.org/" target='_blank' rel='noreferrer'>Explorer</a>
            </FooterLinkItems>
            <FooterLinkItems>
            <a href="https://omniaverse.gitbook.io/omniaverse/the-omniaverse-ecosystem/tokenomics" target='_blank' rel='noreferrer'>Tokenomics</a>
            </FooterLinkItems>
            </FooterLinksWrapper>

        </FooterLinksContainer>

        <SocialMedia>
          <SocialMediaWrapper>
            <SocialLogo to='/' onClick={toggleHome}>
              <img src={logo} height={64} width={64}/>
            </SocialLogo>
            <WebsiteRights>OmniaVerse © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href='https://www.youtube.com/@OmniaVerse' target='_blank' aria-label='Youtube'>
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink href='https://twitter.com/omniaverse' target='_blank' aria-label='Twitter'>
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href='https://t.me/omniaverseOfficial' target='_blank' aria-label='Telegram'>
                <FaTelegram />
              </SocialIconLink>        
            </SocialIcons>
          </SocialMediaWrapper>
        </SocialMedia>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
