import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/auth"

const { REACT_APP_FIREBASE_API_KEY, REACT_APP_FIREBASE_AUTH_DOMAIN, REACT_APP_FIREBASE_PROJECT_ID, REACT_APP_FIREBASE_STORAGE_BUCKET, REACT_APP_FIREBASE_MESSAGING_SENDER_ID, REACT_APP_FIREBASE_APP_ID } = process.env;

// Firebase Configuration Object
const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    //measurementId: REACT_APP_FIREBASE_MEASUREMEMT_ID
}

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;


    let userRef = firestore.doc(`users/${userAuth.uid}`);

    const snapShot = await userRef.get();

    let { displayName, email, photoURL, uid, id } = userAuth;

    if (!snapShot.exists && !id) {
        const createdAt = new Date();
        try {
            await userRef.set({
                displayName,
                email,
                photoURL,
                createdAt,
                ...additionalData,
            })
        } catch (error) {
            console.log("error creating user", error.message)
        }
    }

    let tempEmailId;
    tempEmailId = userAuth.id + "@omniaverse.io";
    let userTelegramRef = firestore.collection(`users`);
    const userTelegramSnapshot = await userTelegramRef.where('email', '==', tempEmailId).get();

    if (userTelegramSnapshot.size == 0 && !uid) {
        const createdAt = new Date();
        try {
            const userInfo = {
                displayName,
                email: tempEmailId,
                photoURL,
                createdAt,
            }
            userRef = await firestore.collection(`users`).add(userInfo);
        } catch (error) {
            console.log("error creating user", error.message)
        }
        
    }

    if(id){
        userTelegramSnapshot.docs.forEach(element => {
            userRef = firestore.doc(`users/${element.id}`);
        });
    }

    return userRef;
    
}

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
            unsubscribe();
            resolve(userAuth);
        }, reject);
    });
}

// Firebase web app init
firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const firestore = firebase.firestore()

// Sign in With Google Setup with popup
export const googleProvider = new firebase.auth.GoogleAuthProvider()
export const twitterProvider = new firebase.auth.TwitterAuthProvider()
googleProvider.setCustomParameters({ prompt: "select_account" })
//twitterProvider.setCustomParameters({ prompt: "select_account" })
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider)
export const signInWithTwitter = () => auth.signInWithPopup(auth, twitterProvider)

export default firebase
