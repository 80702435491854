import './infoModal.scss'
import { useRef } from 'react';
import { motion, AnimatePresence } from "framer-motion"
import { staggerOne, modalOverlayVariants, modalVariants, modalFadeInUpVariants } from "../../utils/motionUtils";
import { hideModalInfo } from "../../redux/infomodal/modal.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectInfoModalContent, selectInfoModalState } from "../../redux/infomodal/modal.selectors";
import { VscChromeClose } from "react-icons/vsc";
import useOutsideClick from "../../hooks/useOutsideClick";
import { FaCopy } from 'react-icons/fa';
import React, { useEffect } from 'react';
import { Widget, addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import logo from '../../images/OmniaOneButton.gif';

const InfoModal = () => {

	const dispatch = useDispatch();
	const modalClosed = useSelector(selectInfoModalState);
	const modalContent = useSelector(selectInfoModalContent);
	const handleModalClose = () => dispatch(hideModalInfo());
	const { overview, video, backdrop_path, fallbackTitle} = modalContent;
	const overviewTextArray = overview?.split('.');
	const modalRef = useRef();

	useEffect(() => {
        addResponseMessage('Welcome to this **awesome** chat!');
      }, []);
    
      const handleNewUserMessage = (newMessage) => {
        console.log(`New message incoming! ${newMessage}`);
        // Now send the message throught the backend API
      };

	useOutsideClick(modalRef, () => {
		if (!modalClosed) handleModalClose();
	});

	return (
		<AnimatePresence exitBeforeEnter>
			{!modalClosed && (
				<>
					<motion.div
						variants={modalOverlayVariants}
						initial="hidden"
						animate="visible"
						exit="hidden"
						key="modalOverlay"
						className={`Modal__overlay ${modalClosed && 'Modal__invisible'}`}
					>
						<motion.div
							key="modal"
							variants={modalVariants}
							ref={modalRef}
							className={`Modal__wrp ${modalClosed && 'Modal__invisible'}`}
						>
							<motion.button
								className="Modal__closebtn"
								onClick={handleModalClose}
							>
								<VscChromeClose />
							</motion.button>
							<div className="Modal__image--wrp">
								<div className="Modal__image--shadow" />
								{video && video != "" ?
									<video className="Modal__image--img" controls autoPlay muted >
									<source src={video && video != "" ? `${video}` : backdrop_path} type="video/mp4"/>
								</video> :
								<img className="Modal__image--img" src={backdrop_path}/>
								}

							</div>
							<motion.div variants={staggerOne} initial="initial" animate="animate" exit="exit" className="Modal__info--wrp">
									{<a
										className="Modal__image--button"
										onClick={() => {navigator.clipboard.writeText(overviewTextArray && overviewTextArray.length > 0 ? overviewTextArray.join(".\n") : '')}}										>
										<FaCopy />
										<span>Copy</span>
									</a>}
									&nbsp;&nbsp;<Widget
										handleNewUserMessage={handleNewUserMessage}
										profileAvatar={logo}
										title="My new awesome title"
										subtitle="And my cool subtitle"
									/>
									<br/><br/>
								<motion.h3 variants={modalFadeInUpVariants} className="Modal__info--title">{fallbackTitle}</motion.h3>
								<motion.p variants={modalFadeInUpVariants} className="Modal__info--description">{overviewTextArray && overviewTextArray.length > 0 ? overviewTextArray.join(".\n") : ''}</motion.p>
														
							</motion.div>
						</motion.div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	)
}

export default InfoModal
