import React, { useState } from 'react';
import { CoverContainer, CoverBg, VideoBg, CoverContent, CoverH1, CoverP, CoverBtnWrapper, ArrowForward, ArrowRight } from './CoverElements';
import { Button } from '../ButtonElements';
import { useHistory } from "react-router-dom";
import logo from "../../images/logo-small.png";

const CoverSection = () => {
  const [hover, setHover] = useState(false);
	let history = useHistory();

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <CoverContainer>
      <CoverBg>
        <VideoBg autoPlay loop muted src={"https://raw.githubusercontent.com/matangazoadmin/artifacts/main/COMMON/LANDING_BACKGROUND.mp4"} type='video/mp4' />
      </CoverBg>
      <CoverContent>
        <center><img src={logo}/></center>
        <CoverH1>OmniaVerse</CoverH1>
        <CoverP>Your gateway to Web-3 gaming.</CoverP>
        <CoverBtnWrapper>
          <Button onClick={() => history.push('/login')} onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'>
            Play Now {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </CoverBtnWrapper>
      </CoverContent>
    </CoverContainer>
  );
};

export default CoverSection;
