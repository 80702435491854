import { combineReducers } from "redux";
import omniaverseGamesReducer from './omniaverse.reducer';
import actionAdventureGamesReducer from './actionAdventure.reducer';
import animationGamesReducer from './animation.reducer';
import strategyGamesReducer from './strategy.reducer';
import horrorGamesReducer from './horror.reducer';
import racingGamesReducer from './racing.reducer';
import familyGamesReducer from './family.reducer';
import kidsGamesReducer from './kids.reducer';
import sciFiFantasyReducer from './sciFiFantasy.reducer';
import trendingGamesReducer from './trending.reducer';

export default combineReducers({
    omniaverseGames: omniaverseGamesReducer,
    actionAdventureGames: actionAdventureGamesReducer,
    animationGames: animationGamesReducer,
    strategyGames: strategyGamesReducer,
    horrorGames: horrorGamesReducer,
    racingGames: racingGamesReducer,
    familyGames: familyGamesReducer,
    kidsGames: kidsGamesReducer,
    sciFiFantasyGames: sciFiFantasyReducer,
    trendingGames: trendingGamesReducer
})