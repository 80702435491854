const omniaOneSrc = require('../../images/OmniaOne.mp4');
const ariaSrc = require('../../images/OwnYourGame.mp4');
const gameAnywhereSrc = require('../../images/GameAnywhere.mp4');

export const aboutObj = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Unleash Your Gamer Identity',
  description: 'Explore your distinct gamer profile and monitor your gaming stats effortlessly in one place. Elevate and flex your gaming experience with personalized insights.',
  buttonLabel: '',
  imgStart: false,
  img: omniaOneSrc,
  alt: '',
  dark: true,
  primary: true,
  darkText: false
};



export const discoverObj = {
  id: 'discover',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Game Anywhere, Anytime',
  description: 'Immerse yourself in the world of gaming without limitations. Enjoy your favorite games seamlessly across a variety of devices including Mac, PC, tablets, mobile phones and more.',
  buttonLabel: '',
  imgStart: true,
  img: gameAnywhereSrc,
  alt: '',
  dark: true,
  primary: true,
  darkText: false
};

export const signupObj = {
  id: 'signup',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Own Your Game',
  description: 'Access all your gaming assets directly on your device. From characters to collectibles to NFTs, your gaming world is right at your fingertips.',
  buttonLabel: '',
  imgStart: false,
  img: ariaSrc,
  alt: '',
  dark: true,
  primary: true,
  darkText: false
};
