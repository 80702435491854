import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { fetchNftDataConfig, fetchPopularDataConfig, fetchGamesDataConfig } from "../utils/dataConfig";

export const useRetrieveData = (type) => {

    const dispatch = useDispatch()
    const [data, setData] = useState(null)

    useEffect(() => {
        let selectedConfigArray = null;
        switch (type) {
            case "nfts":
                selectedConfigArray = fetchNftDataConfig;
                break;
            case "games":
                selectedConfigArray = fetchGamesDataConfig;
                break;
            case "popular":
                selectedConfigArray = fetchPopularDataConfig;
                break;
            default:
                break;
        }

        let isPage = true;
        const rowsData = selectedConfigArray.map(el => {
            dispatch(el.thunk(el.url, isPage))
            let isLarge = false;
            if(el.original == "y"){
                isLarge = true
            }
            return {
                id: el.id,
                title: el.title,
                genre: el.genre,
                selector: el.selector,
                isLarge: isLarge
            }
        })
        setData(rowsData)

    }, [type, dispatch])

    return data
}
