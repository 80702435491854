import { firestore } from '../../firebase/firebaseUtils';

export const addToFavouritesUtil = (favouritesList, favouriteToAdd) => {

    let favouritesForUser = firestore.collection(`favourites`);
    const userId = favouriteToAdd.currentUser.id;
    favouritesForUser.where('userId', '==', userId).get().then(res => {
        const existingFavourites = res.docs.map(doc => doc.data()).map(el => ({
            ...el,
            isFavourite: false
        }));

        let favouriteType = 'games';
        let collectibles = [];
        let games = [];
        if (favouriteToAdd.contract) {
            favouriteType = 'collectibles';
        }

        if (existingFavourites && existingFavourites.length == 0) {
            let favouriteObject = {};
            favouriteObject.userId = userId;
            favouriteObject.favourites = {};
            if (favouriteToAdd.contract) {
                collectibles.push(favouriteToAdd.title);
            } else {
                games.push(favouriteToAdd.title);
            }
            favouriteObject.favourites.collectibles = collectibles;
            favouriteObject.favourites.games = games;
            favouritesForUser.doc(userId).set(favouriteObject);
        } else {
            let favourites = existingFavourites[0].favourites;
            collectibles = favourites.collectibles;
            games = favourites.games;
            if (favouriteType == 'collectibles') {
                collectibles.push(favouriteToAdd.title);
            } else {
                games.push(favouriteToAdd.title);
            }
            favouritesForUser.doc(userId).update({
                'favourites.games': games,
                'favourites.collectibles': collectibles,
            });
        }
        return existingFavourites;
    })
        .catch(error => {
            const errorMessage = error.message;
            console.log(errorMessage);
        });
}

export const removeFromFavouritesUtil = (favouritesList, favouriteToRemove) => {
    const existingFavourite = favouritesList.find(fav => fav.id === favouriteToRemove.id);

    return existingFavourite;
}

export function* userFavouritesUtil(favouritesList, currentUser) {
    try {
        let favouritesForUser = firestore.collection(`favourites`);
        const userId = currentUser.id;
        const res = yield favouritesForUser.where('userId', '==', userId).get();
        const existingFavourites = res.docs.map(doc => doc.data()).map(el => ({
            ...el,
            isFavourite: false
        }));
        return existingFavourites[0];
    } catch (e) {
        return {};
    }
}