import Cookies from 'js-cookie';
import axios from "./axiosInstance";

export const getOneMonthAgoReleaseDate = () => {
    let date = new Date();
    date.setMonth(date.getMonth() - 1);
    let formattedDate = date.toJSON().slice(0, 10);

    return formattedDate;
}

export const dateToYearOnly = date => date.slice(0, 4);

export const capitalizeFirstLetter = text => (
    text.charAt(0).toUpperCase() + text.slice(1)
);

export const randomize = data => (
    Math.floor(Math.random() * data.length - 1)
);

export const truncate = (text, n) => (
    text?.length > n ? text.substr(0, n - 1) + "..." : text
);

export const getTelegramUser = async (currentUser) => {
    if (!currentUser) {
        const telegramAuthDetails = Cookies.get('TELEGRAM_AUTH');
        if (telegramAuthDetails) {
            currentUser = {};
            const telegramDetailsArray = telegramAuthDetails.split(',');
            for (let index = 0; index < telegramDetailsArray.length; index++) {
                const element = telegramDetailsArray[index].split('=');
                if (element[0] == "username") {
                    currentUser.displayName = element[1];
                } else if (element[0] == "id") {
                    currentUser.id = element[1];
                } else if (element[0] == "auth_date") {
                    currentUser.createdAt = element[1];
                } else if (element[0] == "photo_url") {
                    currentUser.photoURL = element[1];
                }
            }
        }
        if (!telegramAuthDetails && window.location.href == "http://localhost:3000/login") {
            currentUser = {};
            const telegramDetailsArray = "username=Omniaverse,photo_url=https://t.me/i/userpic/320/w3pEqz2c9GG72mdvrfpgVPjMR72MVXSTDuzddHUcbXA.jpg,id=2130085096,auth_date=1707880426".split(',');
            for (let index = 0; index < telegramDetailsArray.length; index++) {
                const element = telegramDetailsArray[index].split('=');
                if (element[0] == "username") {
                    currentUser.displayName = element[1];
                } else if (element[0] == "id") {
                    currentUser.id = element[1];
                } else if (element[0] == "auth_date") {
                    currentUser.createdAt = element[1];
                } else if (element[0] == "photo_url") {
                    currentUser.photoURL = element[1];
                }
            }
        }
    }
    if (currentUser == null) {
        return null;
    } else {
        return currentUser;
    }
};


export const getWeb3User = async (currentUser) => {
    if (!currentUser) {
        if (window.ethereum && window.web3) {
            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                if (accounts) {
                    currentUser = {};
                    const data = await callOmniaOneApiForMultipleWallets(accounts);
                    if (data && data.accountBalance) {
                        currentUser.displayName = data.omniaOne.playFabId;
                        currentUser.id = data.omniaOne.id;
                        currentUser.createdAt = data.omniaOne.createdAt;
                        //currentUser.photoURL = ;
                    }

                }
            } catch (error) {
                console.log(error);
                return null;
            }

        }
    }
    if (currentUser == null) {
        return null;
    } else {
        return currentUser;
    }
};

export const removeTelegramUser = async () => {
    const telegramAuthDetails = Cookies.get('TELEGRAM_AUTH');
    if (telegramAuthDetails) {
        Cookies.set('TELEGRAM_AUTH', null);
    }
};

export async function callOmniaOneApiForMultipleWallets(accounts) {
    const response = await axios.get(`omniaone/wallets?wallets=` + accounts.join());
    const data = response.data;
    return data;
}

export async function getOmniaOneAccount(currentUser) {
	const response = await axios.get(`omniaone?email=` + currentUser.email + `&id=` + currentUser.id)
    const data = response.data;
    return data;
}
