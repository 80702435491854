import requests from "./requests";
import * as nftSelectors from "../redux/nfts/nfts.selectors";
import * as gamesSelectors from "../redux/games/games.selectors";
import {
	fetchLoyaltyNftsAsync,
	fetchMyNftsAsync,
	fetchLatestNftsAsync,
	fetchOmniaVerseNftsAsync,
	fetchTopRatedNftsAsync,
	fetchTrendingNftsAsync,
	fetchUpcomingNftsAsync,
	fetchGamingNftsAsync,
} from "../redux/nfts/nfts.actions";
import {
	fetchActionAdventureGamesAsync,
	//fetchAnimationGamesAsync,
	fetchStrategyGamesAsync,
	//fetchHorrorGamesAsync,
	fetchRacingGamesAsync,
	fetchFamilyGamesAsync,
	//fetchKidsGamesAsync,
	fetchOmniaVerseGamesAsync,
	//fetchSciFiFantasyGamesAsync,
	fetchTrendingGamesAsync,
} from "../redux/games/games.actions";
const {
	fetchReleasedNftsByOneMonth,
	fetchTrendingNfts,
	fetchMyNfts,
	fetchOmniaVerseOriginals,
	fetchTopRated,
	fetchLoyaltyNfts,
	fetchGamingNfts,
	fetchUpcomingNfts,
	fetchActionAdventureGames,
	fetchStrategyGames,
	fetchRacingGames,
	fetchFamilyGames,
	fetchTrendingGames,
} = requests;

export const fetchNftDataConfig = [
	{
		id: 0,
		thunk: fetchMyNftsAsync,
		url: fetchMyNfts,
		title: "My Collectibles",
		genre: "myCollectibles",
		selector: nftSelectors.selectMyNfts,
	},
	{
		id: 1,
		thunk: fetchTrendingNftsAsync,
		url: fetchTrendingNfts,
		title: "Trending Now",
		genre: "trending",
		selector: nftSelectors.selectTrendingNfts,
	},
	{
		id: 2,
		thunk: fetchTopRatedNftsAsync,
		url: fetchTopRated,
		title: "Top Rated on OmniaVerse",
		genre: "toprated",
		selector: nftSelectors.selectTopRatedNfts,
	},
	{
		id: 3,
		thunk: fetchOmniaVerseNftsAsync,
		url: fetchOmniaVerseOriginals,
		title: "OmniaVerse Originals",
		genre: "omniaverse",
		selector: nftSelectors.selectOmniaVerseNfts,
		isLarge: true,
	},
	{
		id: 4,
		thunk: fetchLoyaltyNftsAsync,
		url: fetchLoyaltyNfts,
		title: "Loyalty",
		genre: "loyalty",
		selector: nftSelectors.selectLoyaltyNfts,
	},
	{
		id: 5,
		thunk: fetchUpcomingNftsAsync,
		url: fetchUpcomingNfts,
		title: "Upcoming",
		genre: "upcoming",
		selector: nftSelectors.selectUpcomingNfts,
	},
	{
		id: 6,
		thunk: fetchGamingNftsAsync,
		url: fetchGamingNfts,
		title: "Gaming",
		genre: "gaming",
		selector: nftSelectors.selectGamingNfts,
	},
];

export const fetchGamesDataConfig = [
	{
		id: 0,
		thunk: fetchTrendingGamesAsync,
		url: fetchTrendingGames,
		title: "Trending Now",
		genre: "trending",
		selector: gamesSelectors.selectTrendingGames,
	},
	{
		id: 1,
		thunk: fetchOmniaVerseGamesAsync,
		url: fetchOmniaVerseOriginals,
		title: "OmniaVerse Originals",
		genre: "omniaverse",
		selector: gamesSelectors.selectOmniaVerseGames,
		isLarge: true,
	},
	{
		id: 2,
		thunk: fetchActionAdventureGamesAsync,
		url: fetchActionAdventureGames,
		title: "Action & Adventure",
		genre: "actionadventure",
		selector: gamesSelectors.selectActionAdventureGames,
	},
	// {
	// 	id: 3,
	// 	thunk: fetchAnimationGamesAsync,
	// 	url: fetchAnimationGames,
	// 	title: "Animation",
	// 	genre: "animation",
	// 	selector: gamesSelectors.selectAnimationGames,
	// },
	{
		id: 4,
		thunk: fetchStrategyGamesAsync,
		url: fetchStrategyGames,
		title: "Strategy",
		genre: "strategy",
		selector: gamesSelectors.selectStrategyGames,
	},
	// {
	// 	id: 5,
	// 	thunk: fetchHorrorGamesAsync,
	// 	url: fetchHorrorGames,
	// 	title: "Horror",
	// 	genre: "Horror",
	// 	selector: gamesSelectors.selectHorrorGames,
	// },
	{
		id: 6,
		thunk: fetchRacingGamesAsync,
		url: fetchRacingGames,
		title: "Racing",
		genre: "Racing",
		selector: gamesSelectors.selectRacingGames,
	},
	{
		id: 7,
		thunk: fetchFamilyGamesAsync,
		url: fetchFamilyGames,
		title: "Family",
		genre: "family",
		selector: gamesSelectors.selectFamilyGames,
	},
	// {
	// 	id: 8,
	// 	thunk: fetchKidsGamesAsync,
	// 	url: fetchKidsGames,
	// 	title: "Kids",
	// 	genre: "kids",
	// 	selector: gamesSelectors.selectKidsGames,
	// },
	// {
	// 	id: 9,
	// 	thunk: fetchSciFiFantasyGamesAsync,
	// 	url: fetchSciFiFantasyGames,
	// 	title: "Sci-Fi & Fantasy",
	// 	genre: "scififantasy",
	// 	selector: gamesSelectors.selectSciFiFantasyGames,
	// },
];

export const fetchPopularDataConfig = [
	{
		id: 0,
		thunk: fetchTopRatedNftsAsync,
		url: fetchTopRated,
		title: "Top Rated in your country",
		genre: "toprated",
		selector: nftSelectors.selectTopRatedNfts,
	},
	{
		id: 1,
		thunk: fetchLatestNftsAsync,
		url: fetchReleasedNftsByOneMonth,
		title: "New on OmniaVerse",
		genre: "newin",
		selector: nftSelectors.selectLatestNfts,
	},
	{
		id: 2,
		thunk: fetchUpcomingNftsAsync,
		url: fetchUpcomingNfts,
		title: "Upcoming",
		genre: "upcoming",
		selector: nftSelectors.selectUpcomingNfts,
	},
];

export const genresList = [
	{
		id: 28,
		name: "Action",
	},
	{
		id: 11,
		name: "Sports",
	},
	{
		id: 12,
		name: "Adventure",
	},
	{
		id: 16,
		name: "Animation",
	},
	{
		id: 35,
		name: "Strategy",
	},
	{
		id: 80,
		name: "Horror",
	},
	{
		id: 18,
		name: "Drama",
	},
	{
		id: 10751,
		name: "Family",
	},
	{
		id: 14,
		name: "Fantasy",
	},
	{
		id: 36,
		name: "History",
	},
	{
		id: 27,
		name: "Horror",
	},
	{
		id: 10749,
		name: "Racing",
	},
	{
		id: 10770,
		name: "Nft",
	},
	{
		id: 10752,
		name: "War",
	},
	{
		id: 10759,
		name: "Action & Adventure",
	},
	{
		id: 10762,
		name: "Kids",
	},
	{
		id: 10764,
		name: "Reality",
	},
	{
		id: 10765,
		name: "Sci-Fi & Fantasy",
	},
	{
		id: 10768,
		name: "War & Politics",
	},
];

export const chainList = [
	{
		id: 2342,
		name: "OMNIA",
		rpcUrl: "https://rpc.omniaverse.io",
	},
	{
		id: 137,
		name: "POLYGON",
		rpcUrl: "https://polygon-pokt.nodies.app",
	},
];