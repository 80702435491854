import { firestore } from '../../firebase/firebaseUtils';
import { nftsActionTypes } from "./nfts.types";

// Action
export const fetchLoyaltyNftsRequest = () => ({
	type: nftsActionTypes.FETCH_LOYALTY_NFTS_REQUEST,
});

export const fetchLoyaltyNftsSuccess = (loyaltyNfts, isPage) => ({
	type: isPage
		? nftsActionTypes.FETCH_LOYALTY_NFTS_SUCCESS
		: nftsActionTypes.LOAD_MORE_LOYALTY_NFTS_SUCCESS,
	payload: loyaltyNfts,
});

export const fetchLoyaltyNftsFailure = error => ({
	type: nftsActionTypes.FETCH_LOYALTY_NFTS_FAILURE,
	payload: error,
});

export const fetchLoyaltyNftsAsync = (fetchUrl, isPage) => {
	return dispatch => {
		dispatch(fetchLoyaltyNftsRequest());
		firestore.collection(`collectibles`).where('category', '==', 'loyalty')
			.get().then(res => {
				const loyaltyNfts = res.docs.map(doc => doc.data()).map(el => ({
					...el,
					isFavourite: false
				}));
				if (isPage) {
					dispatch(fetchLoyaltyNftsSuccess(loyaltyNfts, isPage));
				} else dispatch(fetchLoyaltyNftsSuccess(loyaltyNfts));
			})
			.catch(error => {
				const errorMessage = error.message;
				dispatch(fetchLoyaltyNftsFailure(errorMessage));
			});
	};
};

export const fetchGamingNftsRequest = () => ({
	type: nftsActionTypes.FETCH_GAMING_NFTS_REQUEST,
});

export const fetchGamingNftsSuccess = (loyaltyNfts, isPage) => ({
	type: isPage
		? nftsActionTypes.FETCH_GAMING_NFTS_SUCCESS
		: nftsActionTypes.LOAD_MORE_GAMING_NFTS_SUCCESS,
	payload: loyaltyNfts,
});

export const fetchGamingNftsFailure = error => ({
	type: nftsActionTypes.FETCH_GAMING_NFTS_FAILURE,
	payload: error,
});

export const fetchGamingNftsAsync = (fetchUrl, isPage) => {
	return dispatch => {
		dispatch(fetchGamingNftsRequest());
		firestore.collection(`collectibles`).where('category', '==', 'gaming')
			.get().then(res => {
				const gamingNfts = res.docs.map(doc => doc.data()).map(el => ({
					...el,
					isFavourite: false
				}));
				if (isPage) {
					dispatch(fetchGamingNftsSuccess(gamingNfts, isPage));
				} else dispatch(fetchGamingNftsSuccess(gamingNfts));
			})
			.catch(error => {
				const errorMessage = error.message;
				dispatch(fetchGamingNftsFailure(errorMessage));
			});
	};
};

// OmniaVerse
export const fetchOmniaVerseNftsRequest = () => ({
	type: nftsActionTypes.FETCH_OMNIAVERSE_NFTS_REQUEST,
});

export const fetchOmniaVerseNftsSuccess = (omniaverseNfts, isPage) => ({
	type: isPage
		? nftsActionTypes.FETCH_OMNIAVERSE_NFTS_SUCCESS
		: nftsActionTypes.LOAD_MORE_OMNIAVERSE_NFTS_SUCCESS,
	payload: omniaverseNfts,
});

export const fetchOmniaVerseNftsFailure = error => ({
	type: nftsActionTypes.FETCH_OMNIAVERSE_NFTS_FAILURE,
	payload: error,
});

export const fetchOmniaVerseNftsAsync = (fetchUrl, isPage) => {
	return dispatch => {
		dispatch(fetchOmniaVerseNftsRequest());
		firestore.collection(`collectibles`).where('category', '==', 'strategy')
			.get().then(res => {
				const omniaverseNfts = res.docs.map(doc => doc.data()).map(el => ({
					...el,
					isFavourite: false
				}));
				if (isPage) {
					dispatch(fetchOmniaVerseNftsSuccess(omniaverseNfts, isPage));
				} else dispatch(fetchOmniaVerseNftsSuccess(omniaverseNfts));
			})
			.catch(error => {
				const errorMessage = error.message;
				dispatch(fetchOmniaVerseNftsFailure(errorMessage));
			});
	};
};

// Top Rated
export const fetchTopRatedNftsRequest = () => ({
	type: nftsActionTypes.FETCH_TOP_RATED_NFTS_REQUEST,
});

export const fetchTopRatedNftsSuccess = (topRatedNfts, isPage) => ({
	type: isPage
		? nftsActionTypes.FETCH_TOP_RATED_NFTS_SUCCESS
		: nftsActionTypes.LOAD_MORE_TOP_RATED_NFTS_SUCCESS,
	payload: topRatedNfts,
});

export const fetchTopRatedNftsFailure = error => ({
	type: nftsActionTypes.FETCH_TOP_RATED_NFTS_FAILURE,
	payload: error,
});

export const fetchTopRatedNftsAsync = (fetchUrl, isPage) => {
	return dispatch => {
		dispatch(fetchTopRatedNftsRequest());
		firestore.collection(`collectibles`).where('category', '==', 'family')
			.get().then(res => {
				const topRatedNfts = res.docs.map(doc => doc.data()).map(el => ({
					...el,
					isFavourite: false
				}));
				if (isPage) {
					dispatch(fetchTopRatedNftsSuccess(topRatedNfts, isPage));
				} else dispatch(fetchTopRatedNftsSuccess(topRatedNfts));
			})
			.catch(error => {
				const errorMessage = error.message;
				dispatch(fetchTopRatedNftsFailure(errorMessage));
			});
	};
};

export const fetchMyNftsRequest = () => ({
	type: nftsActionTypes.FETCH_MY_NFTS_REQUEST,
});

export const fetchMyNftsSuccess = (myNfts, isPage) => ({
	type: isPage
		? nftsActionTypes.FETCH_MY_NFTS_SUCCESS
		: nftsActionTypes.LOAD_MORE_MY_NFTS_SUCCESS,
	payload: myNfts,
});

export const fetchMyNftsFailure = error => ({
	type: nftsActionTypes.FETCH_MY_NFTS_FAILURE,
	payload: error,
});

export const fetchMyNftsAsync = (fetchUrl, isPage) => {
	return dispatch => {
		dispatch(fetchMyNftsRequest());
		firestore.collection(`collectibles`)
			.get().then(res => {
				const myNfts = res.docs.map(doc => doc.data()).map(el => ({
					...el,
					isFavourite: false
				}));
				if (isPage) {
					dispatch(fetchMyNftsSuccess(myNfts, isPage));
				} else dispatch(fetchMyNftsSuccess(myNfts));
			})
			.catch(error => {
				const errorMessage = error.message;
				dispatch(fetchMyNftsFailure(errorMessage));
			});
	};
};

// Trending
export const fetchTrendingNftsRequest = () => ({
	type: nftsActionTypes.FETCH_TRENDING_NFTS_REQUEST,
});

export const fetchTrendingNftsSuccess = (trendingNfts, isPage) => ({
	type: isPage
		? nftsActionTypes.FETCH_TRENDING_NFTS_SUCCESS
		: nftsActionTypes.LOAD_MORE_TRENDING_NFTS_SUCCESS,
	payload: trendingNfts,
});

export const fetchTrendingNftsFailure = error => ({
	type: nftsActionTypes.FETCH_TRENDING_NFTS_FAILURE,
	payload: error,
});

export const fetchTrendingNftsAsync = (fetchUrl, isPage) => {
	return dispatch => {
		dispatch(fetchTrendingNftsRequest());
		firestore.collection(`collectibles`).where('trending', '==', 'y')
			.get().then(res => {
				const trendingNfts = res.docs.map(doc => doc.data()).map(el => ({
					...el,
					isFavourite: false
				}));
				if (isPage) {
					dispatch(fetchTrendingNftsSuccess(trendingNfts, isPage));
				} else dispatch(fetchTrendingNftsSuccess(trendingNfts));
			})
			.catch(error => {
				const errorMessage = error.message;
				dispatch(fetchTrendingNftsFailure(errorMessage));
			});
	};
};

// Upcoming
export const fetchUpcomingNftsRequest = () => ({
	type: nftsActionTypes.FETCH_UPCOMING_NFTS_REQUEST,
});

export const fetchUpcomingNftsSuccess = (upcomingNfts, isPage) => ({
	type: isPage
		? nftsActionTypes.FETCH_UPCOMING_NFTS_SUCCESS
		: nftsActionTypes.LOAD_MORE_UPCOMING_NFTS_SUCCESS,
	payload: upcomingNfts,
});

export const fetchUpcomingTrendingNftsFailure = error => ({
	type: nftsActionTypes.FETCH_UPCOMING_NFTS_FAILURE,
	payload: error,
});

export const fetchUpcomingNftsAsync = (fetchUrl, isPage) => {
	return dispatch => {
		dispatch(fetchUpcomingNftsRequest());
		firestore.collection(`collectibles`).where('category', '==', 'fantasy')
			.get().then(res => {
				const upcomingNfts = res.docs.map(doc => doc.data()).map(el => ({
					...el,
					isFavourite: false
				}));
				if (isPage) {
					dispatch(fetchUpcomingNftsSuccess(upcomingNfts, isPage));
				} else dispatch(fetchUpcomingNftsSuccess(upcomingNfts));
			})
			.catch(error => {
				const errorMessage = error.message;
				dispatch(fetchUpcomingTrendingNftsFailure(errorMessage));
			});
	};
};

// Latest
export const fetchLatestNftsRequest = () => ({
	type: nftsActionTypes.FETCH_LATEST_NFTS_REQUEST,
});

export const fetchLatestNftsSuccess = (latestNfts, isPage) => ({
	type: isPage
		? nftsActionTypes.FETCH_LATEST_NFTS_SUCCESS
		: nftsActionTypes.LOAD_MORE_LATEST_NFTS_SUCCESS,
	payload: latestNfts,
});

export const fetchLatestTrendingNftsFailure = error => ({
	type: nftsActionTypes.FETCH_LATEST_NFTS_FAILURE,
	payload: error,
});

export const fetchLatestNftsAsync = (fetchUrl, isPage) => {
	return dispatch => {
		dispatch(fetchLatestNftsRequest());
		firestore.collection(`collectibles`).where('category', '==', 'fantasy')
			.get().then(res => {
				const latestNfts = res.docs.map(doc => doc.data()).map(el => ({
					...el,
					isFavourite: false
				}));
				if (isPage) {
					dispatch(fetchLatestNftsSuccess(latestNfts, isPage));
				} else dispatch(fetchLatestNftsSuccess(latestNfts));
			})
			.catch(error => {
				const errorMessage = error.message;
				dispatch(fetchLatestTrendingNftsFailure(errorMessage));
			});
	};
};
