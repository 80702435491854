export const nftsActionTypes = {
    FETCH_LOYALTY_NFTS_REQUEST: 'FETCH_LOYALTY_NFTS_REQUEST',
    FETCH_LOYALTY_NFTS_SUCCESS: 'FETCH_LOYALTY_NFTS_SUCCESS',
    FETCH_LOYALTY_NFTS_FAILURE: 'FETCH_LOYALTY_NFTS_FAILURE',
    LOAD_MORE_LOYALTY_NFTS_SUCCESS: 'LOAD_MORE_LOYALTY_NFTS_SUCCESS',

    FETCH_GAMING_NFTS_REQUEST: 'FETCH_GAMING_NFTS_REQUEST',
    FETCH_GAMING_NFTS_SUCCESS: 'FETCH_GAMING_NFTS_SUCCESS',
    FETCH_GAMING_NFTS_FAILURE: 'FETCH_GAMING_NFTS_FAILURE',
    LOAD_MORE_GAMING_NFTS_SUCCESS: 'LOAD_MORE_GAMING_NFTS_SUCCESS',

    FETCH_OMNIAVERSE_NFTS_REQUEST: 'FETCH_OMNIAVERSE_NFTS_REQUEST',
    FETCH_OMNIAVERSE_NFTS_SUCCESS: 'FETCH_OMNIAVERSE_NFTS_SUCCESS',
    FETCH_OMNIAVERSE_NFTS_FAILURE: 'FETCH_OMNIAVERSE_NFTS_FAILURE',
    LOAD_MORE_OMNIAVERSE_NFTS_SUCCESS: 'LOAD_MORE_OMNIAVERSE_NFTS_SUCCESS',

    FETCH_TOP_RATED_NFTS_REQUEST: 'FETCH_TOP_RATED_NFTS_REQUEST',
    FETCH_TOP_RATED_NFTS_SUCCESS: 'FETCH_TOP_RATED_NFTS_SUCCESS',
    FETCH_TOP_RATED_NFTS_FAILURE: 'FETCH_TOP_RATED_NFTS_FAILURE',
    LOAD_MORE_TOP_RATED_NFTS_SUCCESS: 'LOAD_MORE_TOP_RATED_NFTS_SUCCESS',
    
    FETCH_TRENDING_NFTS_REQUEST: 'FETCH_TRENDING_NFTS_REQUEST',
    FETCH_TRENDING_NFTS_SUCCESS: 'FETCH_TRENDING_NFTS_SUCCESS',
    FETCH_TRENDING_NFTS_FAILURE: 'FETCH_TRENDING_NFTS_FAILURE',
    LOAD_MORE_TRENDING_NFTS_SUCCESS: 'LOAD_MORE_TRENDING_NFTS_SUCCESS',

    FETCH_UPCOMING_NFTS_REQUEST: 'FETCH_UPCOMING_NFTS_REQUEST',
    FETCH_UPCOMING_NFTS_SUCCESS: 'FETCH_UPCOMING_NFTS_SUCCESS',
    FETCH_UPCOMING_NFTS_FAILURE: 'FETCH_UPCOMING_NFTS_FAILURE',
    LOAD_MORE_UPCOMING_NFTS_SUCCESS: 'LOAD_MORE_UPCOMING_NFTS_SUCCESS',

    FETCH_LATEST_NFTS_REQUEST: 'FETCH_LATEST_NFTS_REQUEST',
    FETCH_LATEST_NFTS_SUCCESS: 'FETCH_LATEST_NFTS_SUCCESS',
    FETCH_LATEST_NFTS_FAILURE: 'FETCH_LATEST_NFTS_FAILURE',
    LOAD_MORE_LATEST_NFTS_SUCCESS: 'LOAD_MORE_LATEST_NFTS_SUCCESS',

    FETCH_MY_NFTS_REQUEST: 'FETCH_MY_NFTS_REQUEST',
    FETCH_MY_NFTS_SUCCESS: 'FETCH_MY_NFTS_SUCCESS',
    FETCH_MY_NFTS_FAILURE: 'FETCH_MY_NFTS_FAILURE',
    LOAD_MORE_MY_NFTS_SUCCESS: 'LOAD_MORE_MY_NFTS_SUCCESS',
}