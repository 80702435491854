import "./exchange.scss"
import Credits from "../../components/Credits/Credits";
import { motion } from "framer-motion";
import { defaultPageFadeInVariants } from "../../utils/motionUtils";
import Iframe from 'react-iframe';

const Exchange = () => {

    return (
        <motion.div
            className="Exchange"
            variants={defaultPageFadeInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
        <Iframe url="https://exchange.omniaverse.io"
                width="100%"
                height="750"
                id=""
                scrolling="yes"
                className=""
                frameBorder={0}
                display="block"
                position="relative"/>
            <Credits />
        </motion.div>
    )
}

export default Exchange