import './detailModal.scss'
import { useRef } from 'react';
import { motion, AnimatePresence } from "framer-motion"
import { staggerOne, modalOverlayVariants, modalVariants, modalFadeInUpVariants } from "../../utils/motionUtils";
import { hideModalDetail } from "../../redux/modal/modal.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectModalContent, selectModalState } from "../../redux/modal/modal.selectors";
import { VscChromeClose } from "react-icons/vsc";
import { capitalizeFirstLetter, dateToYearOnly } from "../../utils/utils";
import { FaAndroid, FaAppStoreIos, FaApple, FaMinus, FaPlay, FaPlus, FaWindows } from "react-icons/fa";
import { addToFavourites, removeFromFavourites } from "../../redux/favourites/favourites.actions";
import useOutsideClick from "../../hooks/useOutsideClick";
import { genresList } from "../../utils/dataConfig";
import { getChainInfo } from "../../hooks/useTypeConversion";
import { selectCurrentUser } from "../../redux/auth/auth.selectors";


const DetailModal = () => {

	const dispatch = useDispatch();
	const currentUser = useSelector(selectCurrentUser);
	const modalClosed = useSelector(selectModalState);
	const modalContent = useSelector(selectModalContent);
	const handleModalClose = () => dispatch(hideModalDetail());
	const { overview, fallbackTitle, video, release_date, first_air_date, vote_average, original_language, adult, genresConverted, isFavourite, genre_ids, web, android, ios, windows, mac, ids, contract, chainId, backdrop_path} = modalContent;
	const chainDetails = getChainInfo(chainId);
	let joinedGenres = [];
	if(!genresConverted && genre_ids){
		const genreIdsArray = genre_ids.split(",");
		for (let index = 0; index < genreIdsArray.length; index++) {
			const element = genreIdsArray[index];
			for (let j = 0; j < genresList.length; j++) {
				const elementJ = genresList[j];
				if(element == elementJ.id){
					joinedGenres.push(elementJ.name);
				}
			}
		}
		joinedGenres = joinedGenres.join(', ');
	}else{
		joinedGenres = genresConverted ? genresConverted.join(', ') : "Unavailable";
	}
	
	const maturityRating = adult === undefined ? "Unavailable" : adult === "yes" ? "Suitable for adults only" : "Suitable for all ages";
	const reducedDate = release_date ? dateToYearOnly(release_date) : first_air_date ? dateToYearOnly(first_air_date) : "Unavailable";
	const modalRef = useRef();
	const url = window.location.href.endsWith("nfts");

	const handleAdd = (event) => {
		event.stopPropagation();
		dispatch(addToFavourites({ ...modalContent, isFavourite, currentUser }));
	}
	const handleRemove = (event) => {
		event.stopPropagation();
		dispatch(removeFromFavourites({ ...modalContent, isFavourite, currentUser }));
		if (!modalClosed) handleModalClose();
	}
	const handlePlayAnimation = event => {
		event.stopPropagation();
		handleModalClose();
	};
	useOutsideClick(modalRef, () => {
		if (!modalClosed) handleModalClose();
	});

	return (
		<AnimatePresence exitBeforeEnter>
			{!modalClosed && (
				<>
					<motion.div
						variants={modalOverlayVariants}
						initial="hidden"
						animate="visible"
						exit="hidden"
						key="modalOverlay"
						className={`Modal__overlay ${modalClosed && 'Modal__invisible'}`}
					>
						<motion.div
							key="modal"
							variants={modalVariants}
							ref={modalRef}
							className={`Modal__wrp ${modalClosed && 'Modal__invisible'}`}
						>
							<motion.button
								className="Modal__closebtn"
								onClick={handleModalClose}
							>
								<VscChromeClose />
							</motion.button>
							<div className="Modal__image--wrp">
								<div className="Modal__image--shadow" />
								{video && video.endsWith(".mp4") &&
									<video className="Modal__image--img" controls autoPlay muted >
									<source src={video} type="video/mp4"/>
									</video>}
								{video && video.endsWith(".gif") && <img className="Modal__image--img" src={video.endsWith(".gif") ? video : backdrop_path}/>}							
								{backdrop_path && video == '' && <img className="Modal__image--img" src={backdrop_path}/>}							
							</div>
							{!ids && !url && <motion.div variants={staggerOne} initial="initial" animate="animate" exit="exit" className="Modal__info--wrp">
									{web && web != "" && <a
										className="Modal__image--button"
										onClick={handlePlayAnimation}
										target='_blank'
										rel="noreferrer"
										href={"https://"+web}
										>
										<FaPlay />
										<span>Try Now</span>
									</a>}
									{!isFavourite
										? (
											<button className='Modal__image--button-circular' onClick={handleAdd}>
												<FaPlus />
											</button>
										): (
											<button className='Modal__image--button-circular' onClick={handleRemove}>
												<FaMinus />
											</button>
										)}

											{ios && ios != "" && <button className='Modal__image--button-circular' onClick={() => window.open(ios)}>
												<FaAppStoreIos />
											</button>}
											{android && android != "" && <button className='Modal__image--button-circular' onClick={() => window.open(android)}>
												<FaAndroid />
											</button>}				
											{windows && windows != "" && <button className='Modal__image--button-circular' onClick={() => window.open(windows)}>
												<FaWindows />
											</button>}	
											{mac && mac != "" && <button className='Modal__image--button-circular' onClick={() => window.open(mac)}>
												<FaApple />
											</button>}								
										<br/>&nbsp;<br/>
								<motion.h3 variants={modalFadeInUpVariants} className="Modal__info--title">{fallbackTitle}</motion.h3>
								<motion.p variants={modalFadeInUpVariants} className="Modal__info--description">{overview}</motion.p>
								<motion.hr variants={modalFadeInUpVariants} className="Modal__info--line"/>
								<motion.h4 variants={modalFadeInUpVariants} className="Modal__info--otherTitle">Info on <b>{fallbackTitle}</b></motion.h4>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Genres: </span>
									<span className="Modal__info--row-description">{joinedGenres}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>
										{release_date ? "Release date: " : "First air date: "}
									</span>
									<span className="Modal__info--row-description">{reducedDate}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>No of times played: </span>
									<span className="Modal__info--row-description">{vote_average || "Unavailable"}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Original language: </span>
									<span className="Modal__info--row-description">{capitalizeFirstLetter(original_language)}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Age classification: </span>
									<span className="Modal__info--row-description">{maturityRating}</span>
								</motion.div>
							</motion.div>}
							{url && <motion.div variants={staggerOne} initial="initial" animate="animate" exit="exit" className="Modal__info--wrp">
									{web && web != "" && <a
										className="Modal__image--button"
										onClick={handlePlayAnimation}
										target='_blank'
										rel="noreferrer"
										href={"https://"+web}
										>
										<FaPlay />
										<span>Try Now</span>
									</a>}
									{!isFavourite
										? (
											<button className='Modal__image--button-circular' onClick={handleAdd}>
												<FaPlus />
											</button>
										): (
											<button className='Modal__image--button-circular' onClick={handleRemove}>
												<FaMinus />
											</button>
										)}					
										<br/>&nbsp;<br/>
								<motion.h3 variants={modalFadeInUpVariants} className="Modal__info--title">{fallbackTitle}</motion.h3>
								<motion.p variants={modalFadeInUpVariants} className="Modal__info--description">{overview}</motion.p>
								<motion.hr variants={modalFadeInUpVariants} className="Modal__info--line"/>
								<motion.h4 variants={modalFadeInUpVariants} className="Modal__info--otherTitle">Info on <b>{fallbackTitle}</b></motion.h4>
								{ids && <motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Ids: </span>
									<span className="Modal__info--row-description">{ids}</span>
								</motion.div>}
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>
										{release_date ? "Release date: " : "First air date: "}
									</span>
									<span className="Modal__info--row-description">{reducedDate}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Contract: </span>
									<span className="Modal__info--row-description">{contract}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Chain: </span>
									<span className="Modal__info--row-description">{chainDetails.name}</span>
								</motion.div>
							</motion.div>}
						</motion.div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	)
}

export default DetailModal
