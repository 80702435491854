import { searchActionTypes } from "./search.types";
import { firestore } from '../../firebase/firebaseUtils';

export const changeSearchInputValue = inputValue => ({
	type: searchActionTypes.CHANGE_SEARCH_INPUT_VALUE,
	payload: inputValue
})

export const clearSearchInputValue = () => ({
	type: searchActionTypes.CLEAR_SEARCH_INPUT_VALUE
})

export const fetchSearchResultsRequest = searchQuery => ({
	type: searchActionTypes.FETCH_SEARCH_RESULTS_REQUEST,
	payload: searchQuery
})

export const fetchSearchResultsSuccess = searchResults => ({
	type: searchActionTypes.FETCH_SEARCH_RESULTS_SUCCESS,
	payload: searchResults
})

export const fetchSearchResultsFailure = errorMessage => ({
	type: searchActionTypes.FETCH_SEARCH_RESULTS_FAILURE,
	payload: errorMessage
})

export const fetchSearchResultsAsync = searchQuery => {
	let collectionName = 'games';
	const url = window.location.href;
	if(url.includes("nfts")){
		collectionName = 'collectibles';
	}
	return dispatch => {
		dispatch(fetchSearchResultsRequest(searchQuery));
		firestore.collection(collectionName)
			.get().then(res => {
				const searchResultsOutput = res.docs.map(doc => {
					const documentData = doc.data();
					if(documentData.title.toUpperCase().includes(searchQuery.toUpperCase())){
						return doc.data();
					}else{
						return;
					}
				}).map(el => ({
					...el,
					isFavourite: false
				}));
				dispatch(fetchSearchResultsSuccess(searchResultsOutput));
			})
			.catch(error => {
				const errorMessage = error.message;
				dispatch(fetchSearchResultsFailure(errorMessage));
			});
	}
}