import "./splashAnimation.scss"
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import splash_landscape from "./splash_LANDSCAPE.gif"
import splash_portrait from "./splash_PORTRAIT.gif"
//import { TADUM_SOUND_URL } from "../../requests";
import { motion } from "framer-motion";

const SplashAnimation = () => {

	let history = useHistory();
	// const soundRef = useRef(null);

	// const soundNotification = () => {
	// 	const sound = new Audio(TADUM_SOUND_URL);
	// 	const promise = sound.play();

	// 	if (promise !== undefined) {
	// 		promise.then(() => {}).catch(error => console.error(error));
	// 	}
	// }
	let [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		if (window.innerWidth < 720) {
			setIsMobile(true);
		}
	}, [])
	useEffect(() => {
		//setTimeout(() => {
		//	soundNotification();
		//}, 200)
		setTimeout(() => {
			const url = window.location.href;
			if(url){
				var substr = url.substring(url.indexOf("/splash") + 8);
				if(substr.includes(".omniaverse.io")){
					window.location.href='https://' + substr;
				}else{
					history.push('/games')
				}
			}else{
				history.push('/games')
			}
		}, 5700)
	}, [history])

	return (
		<motion.div
			id="SplashAnimation__wrp"
			className='SplashAnimation'
			initial={{opacity: 0}}
			animate={{opacity: 1}}
			exit={{opacity: 0}}
		>
			<img src={isMobile ? splash_portrait : splash_landscape}/>
		</motion.div>
	)
}

export default SplashAnimation