import "./navbar.scss";
import { useState, useRef, useEffect } from "react";
import useViewport from "../../hooks/useViewport";
import useScroll from "../../hooks/useScroll";
import useOutsideClick from "../../hooks/useOutsideClick";
import { motion } from "framer-motion";
import { navbarFadeInVariants } from "../../utils/motionUtils";
import { LOGO_URL, MOBILE_LOGO_URL } from "../../utils/requests";
import { FaCaretDown } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import Searchbar from "../Searchbar/Searchbar";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/auth/auth.selectors";
import { signOutStart } from "../../redux/auth/auth.actions";
import logo from "./logo-small.png";
import Wallet from "./../../images/Wallet.png";
import User from "./../../images/User.png";
import axios from "../../utils/axiosInstance";
import { Button } from '../ButtonElements';
import { useHistory } from "react-router-dom";
import { CoverBtnWrapper } from '../CoverSection/CoverElements';
import { showModalInfo } from "../../redux/infomodal/modal.actions";
import { getOmniaOneAccount } from "../../utils/utils";
import { getAllFavourites } from "../../redux/favourites/favourites.actions";

const Navbar = () => {
	const { width } = useViewport();
	const isScrolled = useScroll(70);
	const [genresNav, setGenresNav] = useState(false);
	const [profileNav, setProfileNav] = useState(false);
	const [omniaOneNav, setOmniaOneNav] = useState(false);
	const [omniaOne, setOmniaOne] = useState();
	const genresNavRef = useRef();
	const omniaOneNavRef = useRef();
	const profileNavRef = useRef();
	const currentUser = useSelector(selectCurrentUser);
	const dispatch = useDispatch();
	let history = useHistory();
	if(currentUser){
		dispatch(getAllFavourites(currentUser));
	}
    

	useEffect(() => {
		if (currentUser) {
			getOmniaOneAccount(currentUser).then(response => setOmniaOne(response));
		}
	}, [currentUser])

	const createNewUser = () => {		
		axios.post('omniaone', {
			id: currentUser.id,
			email: currentUser.email,
		})
			.then(function (response) {
				processCreateNewUser(response, dispatch);
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useOutsideClick(genresNavRef, () => {
		if (genresNav) setGenresNav(false);
	});
	useOutsideClick(omniaOneNavRef, () => {
		if (omniaOneNav) setOmniaOneNav(false);
	});
	useOutsideClick(profileNavRef, () => {
		if (profileNav) setProfileNav(false);
	});

	return (
		<>

			{currentUser ? (<motion.nav
				className={`Navbar ${isScrolled && "Navbar__fixed"}`}
				variants={navbarFadeInVariants}
				initial="hidden"
				animate="visible"
				exit="hidden"
			>
				{width >= 1024 ? (
					<ul className="Navbar__primarynav Navbar__navlinks">
						<li className="Navbar__navlinks--link">
							<NavLink to="/home">
								<img src={logo} />
							</NavLink>
						</li>
						<li className="Navbar__navlinks--link">
							<NavLink to="/games" activeClassName="activeNavLink">
								Games
							</NavLink>
						</li>
						<li className="Navbar__navlinks--link">
							<NavLink to="/nfts" activeClassName="activeNavLink">
								Collectibles
							</NavLink>
						</li>
						<li className="Navbar__navlinks--link">
							<NavLink to="" activeClassName="activeNavLink">
								Tap Tap
							</NavLink>
						</li>
						<li className="Navbar__navlinks--link">
							<NavLink to="/mylist" activeClassName="activeNavLink">
								My List
							</NavLink>
						</li>
						<li className="Navbar__navlinks--link">
							<NavLink to="/exchange" activeClassName="activeNavLink">
								Exchange
							</NavLink>
						</li>
					</ul>
				) : (
					<div
						className={`Navbar__primarynav Navbar__navlinks ${isScrolled && "Navbar__primarynav--scrolled"}`}
						onClick={() => setGenresNav(!genresNav)}
					>
						<span className="Navbar__navlinks--link">Discover</span>
						<FaCaretDown className="Navbar__primarynav--toggler Navbar__primarynav--caret" />
						<div
							className={`Navbar__primarynav--content ${genresNav ? "active" : ""}`}
						>
							{genresNav && (
								<ul
									className="Navbar__primarynav--content-wrp"
									ref={genresNavRef}
								>
									<li className="Navbar__navlinks--link">
										<NavLink to="/games" activeClassName="activeNavLink">
											Games
										</NavLink>
									</li>
									<li className="Navbar__navlinks--link">
										<NavLink to="/nfts" activeClassName="activeNavLink">
											Collectibles
										</NavLink>
									</li>
									<li className="Navbar__navlinks--link">
										<NavLink to="/popular" activeClassName="activeNavLink">
											Tap Tap
										</NavLink>
									</li>
									<li className="Navbar__navlinks--link">
										<NavLink to="/mylist" activeClassName="activeNavLink">
											My List
										</NavLink>
									</li>
									<li className="Navbar__navlinks--link">
										<NavLink to="/exchange" activeClassName="activeNavLink">
											Exchange
										</NavLink>
									</li>
								</ul>
							)}
						</div>
					</div>
				)}
				<div className="Navbar__secondarynav">
					<div className="Navbar__navitem">
						<Searchbar />
					</div>
					<div className="Navbar__navitem">
						<div
							className={`Navbar__navprofile ${omniaOneNav && "active"}`}
							onClick={() => setOmniaOneNav(!omniaOneNav)}
						>
							<img
								className="Navbar__navprofile--avatar Navbar__navprofile--toggler"
								src={Wallet}
							/>
							<FaCaretDown className="Navbar__navprofile--toggler Navbar__navprofile--caret" />
							<div className={`Navbar__navprofile--content ${omniaOneNav ? "active" : ""}`}>
								{omniaOneNav && (
									<ul
										className="Navbar__navprofile--content-wrp"
										ref={omniaOneNavRef}
									>
										{currentUser && (
											<li
											>
												{`Balance: ${omniaOne && omniaOne.accountBalance ? omniaOne.accountBalance : 0} OMNIA's`}
											</li>
										)}
											{currentUser && (
											<li
											>
												{`Address: ${omniaOne && omniaOne.omniaOne ? omniaOne.omniaOne.wallet.substring(omniaOne.omniaOne.wallet.length - 8, omniaOne.omniaOne.wallet.length) : ''}`}
											</li>
										)}
									</ul>
								)}
								{omniaOneNav && (
									<ul
										className="Navbar__navprofile--content-wrp"
										ref={omniaOneNavRef}
									>
										{omniaOne && !omniaOne.accountBalance && currentUser && (currentUser.email || currentUser.displayName) && (
											<li
											//className="Navbar__navlinks--link"
											//onClick={() => dispatch(signOutStart())}
											>
												<CoverBtnWrapper>
													<Button onClick={() => createNewUser()} primary='true' dark='true'>
														Create Account
													</Button>
												</CoverBtnWrapper>
											</li>
										)}
									</ul>
								)}
								{omniaOneNav && (
									<ul
										className="Navbar__navprofile--content-wrp"
										ref={omniaOneNavRef}
									>
										{omniaOne && omniaOne.accountBalance && (
											<li
											//className="Navbar__navlinks--link"
											//onClick={() => dispatch(signOutStart())}
											>
												<CoverBtnWrapper>
													<Button onClick={() => history.push('/login')} primary='true' dark='true'>
														Link Account
													</Button>
												</CoverBtnWrapper>
											</li>
										)}
									</ul>
								)}
							</div>
						</div>
					</div>
					<div className="Navbar__navitem">
						<div
							className={`Navbar__navprofile ${profileNav && "active"}`}
							onClick={() => setProfileNav(!profileNav)}
						>
							<img
								className="Navbar__navprofile--avatar Navbar__navprofile--toggler"
								src={currentUser && currentUser.photoURL && currentUser.photoURL != "undefined" ? currentUser.photoURL : User}
							/>
							<FaCaretDown className="Navbar__navprofile--toggler Navbar__navprofile--caret" />
							<div className={`Navbar__navprofile--content ${profileNav ? "active" : ""}`}>
								{profileNav && (
									<ul
										className="Navbar__navprofile--content-wrp"
										ref={profileNavRef}
									>
										{currentUser && (
											<li
												className="Navbar__navlinks--link"
												onClick={() => dispatch(signOutStart())}
											>
												Sign Out
											</li>
										)}
									</ul>
								)}
							</div>
						</div>
					</div>
				</div>
			</motion.nav>) :
				(
					<motion.nav
						className={`Navbar ${isScrolled && "Navbar__fixed"}`}
						variants={navbarFadeInVariants}
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<Link to="/">
							<img className="Navbar__logo" src={width >= 600 ? LOGO_URL : MOBILE_LOGO_URL} alt="" />
						</Link>
					</motion.nav>
				)}
		</>
	);
};

export default Navbar;

function processCreateNewUser(response, dispatch) {
	let finalData = {};
	finalData.overview = response.data.message;
	if(response.data.message){
		finalData.video = "https://raw.githubusercontent.com/matangazoadmin/artifacts/main/COMMON/LANDING_BACKGROUND.mp4";
		finalData.backdrop_path = "";
		finalData.fallbackTitle = "OmniaOne account created successfully!";
		dispatch(showModalInfo({ ...finalData }));
	}else{
		return;
	}
	
}

