import { genresList, chainList } from "../utils/dataConfig";

export const useGenreConversion = genreIds => {

	if(!genreIds){
		return [];
	}
	let genresConvertedList = [];
	const genreIdsArray = genreIds.split(",");

	genreIdsArray.map(genreId =>
			genresList
				.filter(el => el.id === parseInt(genreId))
				.map(el => genresConvertedList.push(el.name))
		);

	return genresConvertedList;
};

export const useChainConversion = chainIds => {

	if(!chainIds){
		return [];
	}
	let chainConvertedList = [];
	const chainIdsArray = chainIds.split(",");

	chainIdsArray.map(chainId =>
		chainList
				.filter(el => el.id === parseInt(chainId))
				.map(el => chainConvertedList.push(el.name))
		);

	return chainConvertedList;
};

export const getChainInfo = chainId => {
	const chainDetails = chainList
	.filter(el => el.id === parseInt(chainId))
	.map(el => {return el});

	return chainDetails[0];
};