import { createSelector } from "reselect";

const selectInfoModal = state => state.selectInfoModal;

export const selectInfoModalState = createSelector(
	[selectInfoModal],
	selectInfoModal => selectInfoModal.modalIsClosed
)

export const selectInfoModalContent = createSelector(
	[selectInfoModal],
	selectInfoModal => selectInfoModal.modalContent
)