export const gamesActionTypes = {
    FETCH_OMNIAVERSE_GAMES_REQUEST: 'FETCH_OMNIAVERSE_GAMES_REQUEST',
    FETCH_OMNIAVERSE_GAMES_SUCCESS: 'FETCH_OMNIAVERSE_GAMES_SUCCESS',
    FETCH_OMNIAVERSE_GAMES_FAILURE: 'FETCH_OMNIAVERSE_GAMES_FAILURE',
    LOAD_MORE_OMNIAVERSE_GAMES_SUCCESS: 'LOAD_MORE_OMNIAVERSE_GAMES_SUCCESS',

    FETCH_ACTIONADVENTURE_GAMES_REQUEST: 'FETCH_ACTIONADVENTURE_GAMES_REQUEST',
    FETCH_ACTIONADVENTURE_GAMES_SUCCESS: 'FETCH_ACTIONADVENTURE_GAMES_SUCCESS',
    FETCH_ACTIONADVENTURE_GAMES_FAILURE: 'FETCH_ACTIONADVENTURE_GAMES_FAILURE',
    LOAD_MORE_ACTIONADVENTURE_GAMES_SUCCESS: 'LOAD_MORE_ACTIONADVENTURE_GAMES_SUCCESS',

    FETCH_ANIMATION_GAMES_REQUEST: 'FETCH_ANIMATION_GAMES_REQUEST',
    FETCH_ANIMATION_GAMES_SUCCESS: 'FETCH_ANIMATION_GAMES_SUCCESS',
    FETCH_ANIMATION_GAMES_FAILURE: 'FETCH_ANIMATION_GAMES_FAILURE',
    LOAD_MORE_ANIMATION_GAMES_SUCCESS: 'LOAD_MORE_ANIMATION_GAMES_SUCCESS',

    FETCH_STRATEGY_GAMES_REQUEST: 'FETCH_STRATEGY_GAMES_REQUEST',
    FETCH_STRATEGY_GAMES_SUCCESS: 'FETCH_STRATEGY_GAMES_SUCCESS',
    FETCH_STRATEGY_GAMES_FAILURE: 'FETCH_STRATEGY_GAMES_FAILURE',
    LOAD_MORE_STRATEGY_GAMES_SUCCESS: 'LOAD_MORE_STRATEGY_GAMES_SUCCESS',

    FETCH_HORROR_GAMES_REQUEST: 'FETCH_HORROR_GAMES_REQUEST',
    FETCH_HORROR_GAMES_SUCCESS: 'FETCH_HORROR_GAMES_SUCCESS',
    FETCH_HORROR_GAMES_FAILURE: 'FETCH_HORROR_GAMES_FAILURE',
    LOAD_MORE_HORROR_GAMES_SUCCESS: 'LOAD_MORE_HORROR_GAMES_SUCCESS',

    FETCH_RACING_GAMES_REQUEST: 'FETCH_RACING_GAMES_REQUEST',
    FETCH_RACING_GAMES_SUCCESS: 'FETCH_RACING_GAMES_SUCCESS',
    FETCH_RACING_GAMES_FAILURE: 'FETCH_RACING_GAMES_FAILURE',
    LOAD_MORE_RACING_GAMES_SUCCESS: 'LOAD_MORE_RACING_GAMES_SUCCESS',

    FETCH_FAMILY_GAMES_REQUEST: 'FETCH_FAMILY_GAMES_REQUEST',
    FETCH_FAMILY_GAMES_SUCCESS: 'FETCH_FAMILY_GAMES_SUCCESS',
    FETCH_FAMILY_GAMES_FAILURE: 'FETCH_FAMILY_GAMES_FAILURE',
    LOAD_MORE_FAMILY_GAMES_SUCCESS: 'LOAD_MORE_FAMILY_GAMES_SUCCESS',

    FETCH_KIDS_GAMES_REQUEST: 'FETCH_KIDS_GAMES_REQUEST',
    FETCH_KIDS_GAMES_SUCCESS: 'FETCH_KIDS_GAMES_SUCCESS',
    FETCH_KIDS_GAMES_FAILURE: 'FETCH_KIDS_GAMES_FAILURE',
    LOAD_MORE_KIDS_GAMES_SUCCESS: 'LOAD_MORE_KIDS_GAMES_SUCCESS',

    FETCH_SCIFIFANTASY_GAMES_REQUEST: 'FETCH_SCIFIFANTASY_GAMES_REQUEST',
    FETCH_SCIFIFANTASY_GAMES_SUCCESS: 'FETCH_SCIFIFANTASY_GAMES_SUCCESS',
    FETCH_SCIFIFANTASY_GAMES_FAILURE: 'FETCH_SCIFIFANTASY_GAMES_FAILURE',
    LOAD_MORE_SCIFIFANTASY_GAMES_SUCCESS: 'LOAD_MORE_SCIFIFANTASY_GAMES_SUCCESS',

    FETCH_TRENDING_GAMES_REQUEST: 'FETCH_TRENDING_GAMES_REQUEST',
    FETCH_TRENDING_GAMES_SUCCESS: 'FETCH_TRENDING_GAMES_SUCCESS',
    FETCH_TRENDING_GAMES_FAILURE: 'FETCH_TRENDING_GAMES_FAILURE',
    LOAD_MORE_TRENDING_GAMES_SUCCESS: 'LOAD_MORE_TRENDING_GAMES_SUCCESS'
}