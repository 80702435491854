import { createSelector } from "reselect";

export const selectLoyaltyNfts = state => state.nfts.loyaltyNfts;
export const selectOmniaVerseNfts = state => state.nfts.omniaverseNfts;
export const selectTopRatedNfts = state => state.nfts.topRatedNfts;
export const selectMyNfts = state => state.nfts.myNfts;
export const selectTrendingNfts = state => state.nfts.trendingNfts;
export const selectUpcomingNfts = state => state.nfts.upcomingNfts;
export const selectGamingNfts = state => state.nfts.gamingNfts;
export const selectLatestNfts = state => state.nfts.latestNfts;

export const selectLoyaltyNftsSelector = createSelector(
    [selectLoyaltyNfts],
    loyaltyNfts => loyaltyNfts.data
)

export const selectMyNftsSelector = createSelector(
    [selectMyNfts],
    myNfts => myNfts.data
)

export const selectGamingNftsSelector = createSelector(
    [selectGamingNfts],
    gamingNfts => gamingNfts.data
)

export const selectOmniaVerseNftsSelector = createSelector(
    [selectOmniaVerseNfts],
    omniaverseNfts => omniaverseNfts.data
)

export const selectTopRatedNftsSelector = createSelector(
    [selectTopRatedNfts],
    topRatedNfts => topRatedNfts.data
)

export const selectTrendingNftsSelector = createSelector(
    [selectTrendingNfts],
    trendingNfts => trendingNfts.data
)

export const selectUpcomingNftsSelector = createSelector(
    [selectUpcomingNfts],
    upcomingNfts => upcomingNfts.data
)

export const selectLatestNftsSelector = createSelector(
    [selectLatestNfts],
    latestNfts => latestNfts.data
)