import "./banner.scss";
import React from "react";
import { useState,useEffect} from "react";
import { motion } from "framer-motion";
import { staggerOne, bannerFadeInLoadSectionVariants, bannerFadeInVariants, bannerFadeInUpVariants } from "../../utils/motionUtils";
import { FaPlay } from "react-icons/fa";
import { BiInfoCircle } from "react-icons/bi";
import { truncate } from "../../utils/utils";
import SkeletonBanner from "../SkeletonBanner/SkeletonBanner";
import { useDispatch, useSelector } from "react-redux";
import { showModalDetail } from "../../redux/modal/modal.actions";
import { selectTrendingNfts, selectOmniaVerseNfts } from "../../redux/nfts/nfts.selectors";
import { selectOmniaVerseGames } from "../../redux/games/games.selectors";


const Banner = ({ type }) => {
	let selector;
	switch (type) {
		case "nfts":
			selector = selectTrendingNfts;
			break;
		case "games":
			selector = selectOmniaVerseGames;
			break;
		default:
			selector = selectOmniaVerseNfts;
			break;
	}
	const myData = useSelector(selector);
	const { loading, error, data: results } = myData;
	let random = 0;
	if(results && results.length > 0){
		random = Math.floor(Math.random() * results.length);
	}
	
	const finalData = results[random];
	let backdropPath = finalData?.backdrop_path;
	const [backDropPath, setBackDropPath] = useState("");
	useEffect(() => {
		if (window.innerWidth < 720) {
			if(backdropPath != null){
				setBackDropPath(backdropPath.replace("LANDSCAPE","PORTRAIT"));
			}
		}
    }, [backdropPath])
	const fallbackTitle = finalData?.title || finalData?.name || finalData?.original_name;
	const web = finalData?.web;
	const description = truncate(finalData?.overview, 150);
	const dispatch = useDispatch();

	const handlePlayAnimation = event => {
		event.stopPropagation();
	};

	const handleModalOpening = () => {
		dispatch(showModalDetail({ ...finalData, fallbackTitle }));
	}

	return (
		<>
			<motion.section
				variants={bannerFadeInLoadSectionVariants}
				initial='initial'
				animate='animate'
				exit='exit'
				className="Banner__loadsection"
			>
				{loading && <SkeletonBanner />}
				{error && <div className="errored">Oops, an error occurred.</div>}
			</motion.section>

			{!loading && finalData && (
				<motion.header
					variants={bannerFadeInVariants}
					initial='initial'
					animate='animate'
					exit='exit'
					className="Banner"
					style={{backgroundImage: `url(${backDropPath && backDropPath != ''? backDropPath : backdropPath})`}}
				>
					<motion.div
						className="Banner__content"
						variants={staggerOne}
						initial='initial'
						animate='animate'
						exit='exit'
					>
						<motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--title">{fallbackTitle}</motion.h1>
						<motion.div variants={bannerFadeInUpVariants} className="Banner__buttons">
							{web && web != "" && <a
								className="Banner__button"
								onClick={handlePlayAnimation}
								href={"https://"+web}
							>
								<FaPlay />
								<span>Try Now</span>
							</a>}
							<button
								className="Banner__button"
								onClick={handleModalOpening}
							>
								<BiInfoCircle size="1.5em" />
								<span>More info</span>
							</button>
						</motion.div>
						<motion.p variants={bannerFadeInUpVariants} className="Banner__content--description">{description}</motion.p>
					</motion.div>
					<div className="Banner__panel" />
					<div className="Banner__bottom-shadow" />
				</motion.header>
			)}
		</>
	)
}

export default React.memo(Banner);