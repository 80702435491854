import { combineReducers } from "redux";
import loyaltyNftsReducer from "./loyalty.reducer";
import gamingNftsReducer from "./gaming.reducer";
import omniaverseNftsReducer from "./omniaverse.reducer";
import topRatedNftsReducer from "./topRated.reducer";
import trendingNftsReducer from "./trending.reducer";
import upcomingNftsReducer from './upcoming.reducer';
import latestReleasedNftsReducer from "./latestReleased.reducer";
import myNftsReducer from "./myCollectibles.reducer";

export default combineReducers({
    loyaltyNfts: loyaltyNftsReducer,
    gamingNfts: gamingNftsReducer,
    omniaverseNfts: omniaverseNftsReducer,
    topRatedNfts: topRatedNftsReducer,
    trendingNfts: trendingNftsReducer,
    upcomingNfts: upcomingNftsReducer,
    latestNfts: latestReleasedNftsReducer,
    myNfts: myNftsReducer,
})