import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Navbar from "./components/Navbar/Navbar"
//import Homepage from "./pages/Homepage/Homepage"
import Home from "./pages/Home"
import Nfts from "./pages/Nfts/Nfts"
import Games from './pages/Games/Games';
import Popular from "./pages/Popular/Popular";
import MyList from './pages/MyList/MyList';
import Auth from "./pages/Auth/Auth";
import Search from "./pages/Search/Search";
import Category from "./pages/Category/Category";
import DetailModal from "./components/DetailModal/DetailModal";
import SplashAnimation from "./components/SplashAnimation/SplashAnimation";
//import PlayAnimation from "./components/PlayAnimation/PlayAnimation";
import { selectCurrentUser } from './redux/auth/auth.selectors';
import { selectSearchResults } from "./redux/search/search.selectors";
import { checkUserSession } from "./redux/auth/auth.actions";
import InfoModal from "./components/InfoModal/InfoModal";
import Exchange from "./pages/Exchange/Exchange";

const App = () => {

    const currentUser = useSelector(selectCurrentUser);
    const searchResults = useSelector(selectSearchResults);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(checkUserSession());
    }, [dispatch])

    return (
        <div className="App">
            {currentUser && (
                <>
                    <Navbar />
                    <DetailModal />
                    <InfoModal />
                </>
            )}
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route
                        exact
                        path="/"
                        render={() => currentUser ? <Games /> : <Home />}
                    />
                    <Route
                        exact
                        path="/login"
                        render={() => currentUser ? <Games /> : <Auth />}
                    />
                    <Route
                        path="/splash"
                        component={SplashAnimation}
                    />
                    <Route
                        path="/search"
                        render={() => currentUser
                            ? (searchResults && <Search results={searchResults}/>)
                            : <Redirect to="/" />}
                    />
                    <Route
                        exact
                        path="/games"
                        render={() => currentUser ? <Games /> : <Redirect to="/" />}
                    />
                    <Route
                        exact
                        path="/games/:categoryName"
                        render={(props) => currentUser
                            ? <Category {...props} />
                            : <Redirect to="/" />}
                    />
                    <Route
                        exact
                        path="/nfts"
                        render={() => currentUser ? <Nfts /> : <Redirect to="/" />}
                    />
                    <Route
                        exact
                        path="/nfts/:categoryName"
                        render={(props) => currentUser
                            ? <Category {...props} />
                            : <Redirect to="/" />}
                    />
                    <Route
                        exact
                        path="/popular"
                        render={() => currentUser ? <Popular /> : <Redirect to="/" />}
                    />
                    <Route
                        exact
                        path="/popular/:categoryName"
                        render={(props) => currentUser
                            ? <Category {...props} />
                            : <Redirect to="/" />}
                    />
                    <Route
                        exact
                        path="/mylist"
                        render={() => currentUser ? <MyList /> : <Redirect to="/" />}
                    />
                    <Route
                        exact
                        path="/exchange"
                        render={() => currentUser ? <Exchange /> : <Redirect to="/" />}
                    />
                    <Route
                        exact
                        path="/"
                        render={() => currentUser ? <Redirect to="/splash"/> : <Auth />}
                    />
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </AnimatePresence>
        </div>
    )
}

export default App;
