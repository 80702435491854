import "./row.scss";
import RowPoster from "../RowPoster/RowPoster";
import SkeletonElement from "../SkeletonElement/SkeletonElement";
import SkeletonPoster from "../SkeletonPoster/SkeletonPoster";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import useViewport from "../../hooks/useViewport";
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
//import { FiChevronRight } from "react-icons/fi";
import { useEffect, useState } from 'react';
import nftContract from '../../utils/nftContract.json';
import { callOmniaOneApiForMultipleWallets, getOmniaOneAccount } from "../../utils/utils";
import { selectCurrentUser } from '../../redux/auth/auth.selectors';
import { getChainInfo } from "../../hooks/useTypeConversion";

// Swiper
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Pagination]);
const { Web3 } = require('web3');


const Row = ({ selector, title, genre, isLarge }) => {
	const { width } = useViewport();
	const rowData = useSelector(selector);
	let { loading, error, data: results } = rowData;
	const { pathname } = useLocation();
	const [nftAvailable, setNftAvailable] = useState(false);
	const [userAccount, setUserAccount] = useState();
	const [processedOnce, setProcessedOnce] = useState(false);
	let [myNftsResults, setMyNftsResults] = useState([]);
	const currentUser = useSelector(selectCurrentUser);

	useEffect(() => {

		if(!processedOnce){

			for (let index = 0; index < results.length; index++) {
				const result = results[index];
				const chainDetails = getChainInfo(result.chainId);
	
				if (chainDetails) {
	
					let web3 = new Web3(chainDetails.rpcUrl);
					if (title && title == "My Collectibles") {
						if (window.ethereum && !userAccount) {
							window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts =>
								callOmniaOneApiForMultipleWallets(accounts).then(response => {
									if (response && response.omniaOne) {
										setUserAccount(response.omniaOne);
									}
								}))
						}
						if(currentUser && currentUser.id != 'undefined' && !userAccount){
							getOmniaOneAccount(currentUser).then(response => {
								if (response && response.omniaOne) {
									setUserAccount(response.omniaOne);
								}
							})
						}
						if (userAccount) {
							const contractInstance = new web3.eth.Contract(nftContract, result.contract);
							try {
								contractInstance.methods.walletOfOwner(userAccount.wallet).call().then(balance => {
									if (balance.length > 0) {
										setNftAvailable(true);
										let myNftsResult = myNftsResults ? myNftsResults : [];
										let nftDetails = result;
										nftDetails.ids = balance.join();
										myNftsResult.push(nftDetails)
										setMyNftsResults(myNftsResult)
									}
								}).catch(console.log(" "));
							} catch (error) {
								console.log(error);
								contractInstance.methods.balanceOf(userAccount.wallet).call().then(balance => {
									if (balance > 0) {
										setNftAvailable(true);
										let myNftsResult = myNftsResults ? myNftsResults : [];
										let nftDetails = result;
										nftDetails.ids = balance;
										myNftsResult.push(nftDetails)
										setMyNftsResults(myNftsResult)
									}
								})
							}

							if (!nftAvailable) {
								for (let index = 0; index < userAccount.secondaryWallets.length; index++) {
									const wallet = userAccount.secondaryWallets[index];
									try{
										contractInstance.methods.walletOfOwner(wallet).call().then(balance => {
											if (balance.length > 0) {
												setNftAvailable(true);
												let myNftsResult = myNftsResults ? myNftsResults : [];
												let nftDetails = result;
												nftDetails.ids = balance.join();
												myNftsResult.push(nftDetails)
												setMyNftsResults(myNftsResult)
											}
										}).catch(console.log(" "));
									}catch(error){
										console.log(error);
										contractInstance.methods.balanceOf(wallet).call().then(balance => {
											if (balance > 0) {
												setNftAvailable(true);
												let myNftsResult = myNftsResults ? myNftsResults : [];
												let nftDetails = result;
												nftDetails.ids = balance;
												myNftsResult.push(nftDetails)
												setMyNftsResults(myNftsResult)
											}
										})
									}
								}
							}
							setProcessedOnce(true);
						}
					}
				}
		}

		}

	}, [title, userAccount, currentUser, nftAvailable, results, myNftsResults, processedOnce]);
	//Custom Swiper config
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const customSwiperParams = {
		observer: true,
		observeParents: true,
		navigation: {
			prevEl: navigationPrevRef.current,
			nextEl: navigationNextRef.current,
		},
		breakpoints: {
			1378: { slidesPerView: 6, slidesPerGroup: 6 },
			998: { slidesPerView: 4, slidesPerGroup: 4 },
			625: { slidesPerView: 3, slidesPerGroup: 3 },
			330: { slidesPerView: 2, slidesPerGroup: 2 },
			0: { slidesPerView: 1.5, slidesPerGroup: 1.5 }
		},
		loopAdditionalSlides: width >= 1378 ? 5 : width >= 998 ? 3 : width >= 625 ? 2 : 2,
		pagination: true,
		loop: false,
		grabCursor: false,
		draggable: false,
		preventClicksPropagation: true,
		preventClicks: true,
		slideToClickedSlide: false,
		allowTouchMove: true
	};

	const rightMouseOver = (e) => {
		if (e.currentTarget.classList.contains('right')) { e.currentTarget.parentElement.classList.add('is-right') }
		else if (e.currentTarget.classList.contains('left')) { e.currentTarget.parentElement.classList.add('is-left') }
	}

	const rightMouseOut = (e) => {
		e.currentTarget.parentElement.classList.remove('is-right', 'is-left')
	}

	const insertPositionClassName = (index) => {
		const i = index + 1

		if (i === 1) return 'left'
		else if (i === 20) return 'right'

		if (width >= 1378) {
			if ([7, 13, 19].includes(i)) return 'left'
			else if ([6, 12, 18].includes(i)) return 'right'
		} else if (width >= 998) {
			if ([5, 9, 13, 17].includes(i)) return 'left'
			else if ([4, 8, 12, 16].includes(i)) return 'right'
		} else if (width >= 768) {
			if ([4, 7, 10, 13, 16].includes(i)) return 'left'
			else if ([3, 6, 9, 12, 15, 18].includes(i)) return 'right'
		}
	}

	return (
		<div className="Row">
			{error && <div className='Row__not-loaded'>Oops, an error occurred.</div>}
			{loading ?
				(
					<div className='Row__not-loaded'>
						<SkeletonElement type="title" />
						<SkeletonPoster />
					</div>
				) : (
					<h3 className="Row__title">
						<Link to={`${pathname}/${genre}`}>
							<span>{title}</span>
							{/* <span className='Row__showmore'>Show all <FiChevronRight/></span> */}
						</Link>
					</h3>
				)
			}
			{!loading && !error && (
				<div className="Row__poster--wrp">
					<div className="Row__slider--mask left" ref={navigationPrevRef}>
						<MdChevronLeft className="Row__slider--mask-icon left" size="3em" style={{ color: 'white' }} />
					</div>
					<div className="Row__slider--mask right" ref={navigationNextRef}>
						<MdChevronRight className="Row__slider--mask-icon right" size="3em" style={{ color: 'white' }} />
					</div>
					<Swiper
						{...customSwiperParams}
						onBeforeInit={(swiper) => {
							swiper.params.navigation.prevEl = navigationPrevRef.current;
							swiper.params.navigation.nextEl = navigationNextRef.current;
						}}
					>
						{!loading &&
							results &&
							title != "My Collectibles" &&
							results.map((result, i) => (
								<SwiperSlide
									key={result.id}
									className={insertPositionClassName(i)}
									onMouseOver={rightMouseOver}
									onMouseOut={rightMouseOut}
								>
									<RowPoster
										item={result}
										selector={selector}
										isLarge={isLarge}
										isFavourite={result.isFavourite}
										key={result.id}
									/>
								</SwiperSlide>
							))
						}

						{!loading &&
							myNftsResults &&
							title == "My Collectibles" &&
							myNftsResults.map((result, i) => (
								<SwiperSlide
									key={result.id}
									className={insertPositionClassName(i)}
									onMouseOver={rightMouseOver}
									onMouseOut={rightMouseOut}
								>
									<RowPoster
										item={result}
										selector={selector}
										isLarge={isLarge}
										isFavourite={result.isFavourite}
										key={result.id}
									/>
								</SwiperSlide>
							)

							)
						}
					</Swiper>
				</div>
			)}
		</div>
	);
};

export default Row;
