import { firestore } from '../../firebase/firebaseUtils';
import { gamesActionTypes } from './games.types';

// OmniaVerse
export const fetchOmniaVerseGamesRequest = () => ({
    type: gamesActionTypes.FETCH_OMNIAVERSE_GAMES_REQUEST
})

export const fetchOmniaVerseGamesSuccess = (omniaverseGames, isPage) => ({
    type: isPage
        ? gamesActionTypes.FETCH_OMNIAVERSE_GAMES_SUCCESS
        : gamesActionTypes.LOAD_MORE_OMNIAVERSE_GAMES_SUCCESS,
    payload: omniaverseGames
})

export const fetchOmniaVerseGamesFailure = errorMessage => ({
    type: gamesActionTypes.FETCH_OMNIAVERSE_GAMES_FAILURE,
    payload: errorMessage
})

export const fetchOmniaVerseGamesAsync = (fetchUrl, isPage) => {

    return dispatch => {
        dispatch(fetchOmniaVerseGamesRequest());
        firestore.collection(`games`)
            .get().then(res => {
                const omniaverseGames = res.docs.map(doc => doc.data()).map(el => ({
                    ...el,
                    isFavourite: false
                }));
                if (isPage) {
                    dispatch(fetchOmniaVerseGamesSuccess(omniaverseGames, isPage));
                } else dispatch(fetchOmniaVerseGamesSuccess(omniaverseGames));
            }
            ).catch(error => {
                const errorMessage = error.message;
                dispatch(fetchOmniaVerseGamesFailure(errorMessage));
            })
    }
}


// Action & Adventure
export const fetchActionAdventureGamesRequest = () => ({
    type: gamesActionTypes.FETCH_ACTIONADVENTURE_GAMES_REQUEST
})

export const fetchActionAdventureGamesSuccess = (actionAdventureGames, isPage) => ({
    type: isPage
        ? gamesActionTypes.FETCH_ACTIONADVENTURE_GAMES_SUCCESS
        : gamesActionTypes.LOAD_MORE_ACTIONADVENTURE_GAMES_SUCCESS,
    payload: actionAdventureGames
})

export const fetchActionAdventureGamesFailure = errorMessage => ({
    type: gamesActionTypes.FETCH_ACTIONADVENTURE_GAMES_FAILURE,
    payload: errorMessage
})

export const fetchActionAdventureGamesAsync = (fetchUrl, isPage) => {
    return dispatch => {
        dispatch(fetchActionAdventureGamesRequest());
        firestore.collection(`games`).where('category', '==', 'action')
            .get().then(res => {
                const actionAdventureGames = res.docs.map(doc => doc.data()).map(el => ({
                    ...el,
                    isFavourite: false
                }));
                if (isPage) {
                    dispatch(fetchActionAdventureGamesSuccess(actionAdventureGames, isPage));
                } else dispatch(fetchActionAdventureGamesSuccess(actionAdventureGames));
            }
            ).catch(error => {
                const errorMessage = error.message;
                dispatch(fetchActionAdventureGamesFailure(errorMessage));
            })
    }
}


// SplashAnimation
export const fetchAnimationGamesRequest = () => ({
    type: gamesActionTypes.FETCH_ANIMATION_GAMES_REQUEST
})

export const fetchAnimationGamesSuccess = (animationGames, isPage) => ({
    type: isPage
        ? gamesActionTypes.FETCH_ANIMATION_GAMES_SUCCESS
        : gamesActionTypes.LOAD_MORE_ANIMATION_GAMES_SUCCESS,
    payload: animationGames
})

export const fetchAnimationGamesFailure = errorMessage => ({
    type: gamesActionTypes.FETCH_ANIMATION_GAMES_FAILURE,
    payload: errorMessage
})

export const fetchAnimationGamesAsync = (fetchUrl, isPage) => {
    return dispatch => {
        dispatch(fetchAnimationGamesRequest());
        firestore.collection(`games`).where('category', '==', 'animation')
            .get().then(res => {
                const animationGames = res.docs.map(doc => doc.data()).map(el => ({
                    ...el,
                    isFavourite: false
                }));
                if (isPage) {
                    dispatch(fetchAnimationGamesSuccess(animationGames, isPage));
                } else dispatch(fetchAnimationGamesSuccess(animationGames));
            }
            ).catch(error => {
                const errorMessage = error.message;
                dispatch(fetchAnimationGamesFailure(errorMessage));
            })
    }
}


// Strategy
export const fetchStrategyGamesRequest = () => ({
    type: gamesActionTypes.FETCH_STRATEGY_GAMES_REQUEST
})

export const fetchStrategyGamesSuccess = (strategyGames, isPage) => ({
    type: isPage
        ? gamesActionTypes.FETCH_STRATEGY_GAMES_SUCCESS
        : gamesActionTypes.LOAD_MORE_STRATEGY_GAMES_SUCCESS,
    payload: strategyGames
})

export const fetchStrategyGamesFailure = errorMessage => ({
    type: gamesActionTypes.FETCH_STRATEGY_GAMES_FAILURE,
    payload: errorMessage
})

export const fetchStrategyGamesAsync = (fetchUrl, isPage) => {
    return dispatch => {
        dispatch(fetchStrategyGamesRequest());
        firestore.collection(`games`).where('category', '==', 'strategy')
            .get().then(res => {
                const strategyGames = res.docs.map(doc => doc.data()).map(el => ({
                    ...el,
                    isFavourite: false
                }));
                if (isPage) {
                    dispatch(fetchStrategyGamesSuccess(strategyGames, isPage));
                } else dispatch(fetchStrategyGamesSuccess(strategyGames));
            }
            ).catch(error => {
                const errorMessage = error.message;
                dispatch(fetchStrategyGamesFailure(errorMessage));
            })
    }
}


// Horror
export const fetchHorrorGamesRequest = () => ({
    type: gamesActionTypes.FETCH_HORROR_GAMES_REQUEST
})

export const fetchHorrorGamesSuccess = (horrorGames, isPage) => ({
    type: isPage
        ? gamesActionTypes.FETCH_HORROR_GAMES_SUCCESS
        : gamesActionTypes.LOAD_MORE_HORROR_GAMES_SUCCESS,
    payload: horrorGames
})

export const fetchHorrorGamesFailure = errorMessage => ({
    type: gamesActionTypes.FETCH_HORROR_GAMES_FAILURE,
    payload: errorMessage
})

export const fetchHorrorGamesAsync = (fetchUrl, isPage) => {
    return dispatch => {
        dispatch(fetchHorrorGamesRequest());
        firestore.collection(`games`).where('category', '==', 'horror')
            .get().then(res => {
                const horrorGames = res.docs.map(doc => doc.data()).map(el => ({
                    ...el,
                    isFavourite: false
                }));
                if (isPage) {
                    dispatch(fetchHorrorGamesSuccess(horrorGames, isPage));
                } else dispatch(fetchHorrorGamesSuccess(horrorGames));
            }
            ).catch(error => {
                const errorMessage = error.message;
                dispatch(fetchHorrorGamesFailure(errorMessage));
            })
    }
}


// Racing
export const fetchRacingGamesRequest = () => ({
    type: gamesActionTypes.FETCH_RACING_GAMES_REQUEST
})

export const fetchRacingGamesSuccess = (racingGames, isPage) => ({
    type: isPage
        ? gamesActionTypes.FETCH_RACING_GAMES_SUCCESS
        : gamesActionTypes.LOAD_MORE_RACING_GAMES_SUCCESS,
    payload: racingGames
})

export const fetchRacingGamesFailure = errorMessage => ({
    type: gamesActionTypes.FETCH_RACING_GAMES_FAILURE,
    payload: errorMessage
})

export const fetchRacingGamesAsync = (fetchUrl, isPage) => {
    return dispatch => {
        dispatch(fetchRacingGamesRequest());
        firestore.collection(`games`).where('category', '==', 'racing')
            .get().then(res => {
                const racingGames = res.docs.map(doc => doc.data()).map(el => ({
                    ...el,
                    isFavourite: false
                }));
                if (isPage) {
                    dispatch(fetchRacingGamesSuccess(racingGames, isPage));
                } else dispatch(fetchRacingGamesSuccess(racingGames));
            }
            ).catch(error => {
                const errorMessage = error.message;
                dispatch(fetchRacingGamesFailure(errorMessage));
            })
    }
}


// Family
export const fetchFamilyGamesRequest = () => ({
    type: gamesActionTypes.FETCH_FAMILY_GAMES_REQUEST
})

export const fetchFamilyGamesSuccess = (familyGames, isPage) => ({
    type: isPage
        ? gamesActionTypes.FETCH_FAMILY_GAMES_SUCCESS
        : gamesActionTypes.LOAD_MORE_FAMILY_GAMES_SUCCESS,
    payload: familyGames
})

export const fetchFamilyGamesFailure = errorMessage => ({
    type: gamesActionTypes.FETCH_FAMILY_GAMES_FAILURE,
    payload: errorMessage
})

export const fetchFamilyGamesAsync = (fetchUrl, isPage) => {
    return dispatch => {
        dispatch(fetchFamilyGamesRequest());
        firestore.collection(`games`).where('category', '==', 'family')
            .get().then(res => {
                const familyGames = res.docs.map(doc => doc.data()).map(el => ({
                    ...el,
                    isFavourite: false
                }));
                if (isPage) {
                    dispatch(fetchFamilyGamesSuccess(familyGames, isPage));
                } else dispatch(fetchFamilyGamesSuccess(familyGames));
            }
            ).catch(error => {
                const errorMessage = error.message;
                dispatch(fetchFamilyGamesFailure(errorMessage));
            })
    }
}


// Kids
export const fetchKidsGamesRequest = () => ({
    type: gamesActionTypes.FETCH_KIDS_GAMES_REQUEST
})

export const fetchKidsGamesSuccess = (kidsGames, isPage) => ({
    type: isPage
        ? gamesActionTypes.FETCH_KIDS_GAMES_SUCCESS
        : gamesActionTypes.LOAD_MORE_KIDS_GAMES_SUCCESS,
    payload: kidsGames
})

export const fetchKidsGamesFailure = errorMessage => ({
    type: gamesActionTypes.FETCH_KIDS_GAMES_FAILURE,
    payload: errorMessage
})

export const fetchKidsGamesAsync = (fetchUrl, isPage) => {
    return dispatch => {
        dispatch(fetchKidsGamesRequest());
        firestore.collection(`games`).where('category', '==', 'kids')
            .get().then(res => {
                const kidsGames = res.docs.map(doc => doc.data()).map(el => ({
                    ...el,
                    isFavourite: false
                }));
                if (isPage) {
                    dispatch(fetchKidsGamesSuccess(kidsGames, isPage));
                } else dispatch(fetchKidsGamesSuccess(kidsGames));
            }
            ).catch(error => {
                const errorMessage = error.message;
                dispatch(fetchKidsGamesFailure(errorMessage));
            })
    }
}


// Sci Fi & Fantasy
export const fetchSciFiFantasyGamesRequest = () => ({
    type: gamesActionTypes.FETCH_SCIFIFANTASY_GAMES_REQUEST
})

export const fetchSciFiFantasyGamesSuccess = (sciFiFantasyGames, isPage) => ({
    type: isPage
        ? gamesActionTypes.FETCH_SCIFIFANTASY_GAMES_SUCCESS
        : gamesActionTypes.LOAD_MORE_SCIFIFANTASY_GAMES_SUCCESS,
    payload: sciFiFantasyGames
})

export const fetchSciFiFantasyGamesFailure = errorMessage => ({
    type: gamesActionTypes.FETCH_SCIFIFANTASY_GAMES_FAILURE,
    payload: errorMessage
})

export const fetchSciFiFantasyGamesAsync = (fetchUrl, isPage) => {
    return dispatch => {
        dispatch(fetchSciFiFantasyGamesRequest());
        firestore.collection(`games`).where('category', '==', 'fantasy')
            .get().then(res => {
                const sciFiFantasyGames = res.docs.map(doc => doc.data()).map(el => ({
                    ...el,
                    isFavourite: false
                }));
                if (isPage) {
                    dispatch(fetchSciFiFantasyGamesSuccess(sciFiFantasyGames, isPage));
                } else dispatch(fetchSciFiFantasyGamesSuccess(sciFiFantasyGames));
            }
            ).catch(error => {
                const errorMessage = error.message;
                dispatch(fetchSciFiFantasyGamesFailure(errorMessage));
            })
    }
}


// Trending
export const fetchTrendingGamesRequest = () => ({
    type: gamesActionTypes.FETCH_TRENDING_GAMES_REQUEST
})

export const fetchTrendingGamesSuccess = (trendingGames, isPage) => ({
    type: isPage
        ? gamesActionTypes.FETCH_TRENDING_GAMES_SUCCESS
        : gamesActionTypes.LOAD_MORE_TRENDING_GAMES_SUCCESS,
    payload: trendingGames
})

export const fetchTrendingGamesFailure = errorMessage => ({
    type: gamesActionTypes.FETCH_TRENDING_GAMES_FAILURE,
    payload: errorMessage
})

export const fetchTrendingGamesAsync = (fetchUrl, isPage) => {
    return dispatch => {
        dispatch(fetchTrendingGamesRequest());
        firestore.collection(`games`).where('trending', '==', 'y')
            .get().then(res => {
                const trendingGames = res.docs.map(doc => doc.data()).map(el => ({
                    ...el,
                    isFavourite: false
                }));
                if (isPage) {
                    dispatch(fetchTrendingGamesSuccess(trendingGames, isPage));
                } else dispatch(fetchTrendingGamesSuccess(trendingGames));
            }
            ).catch(error => {
                const errorMessage = error.message;
                dispatch(fetchTrendingGamesFailure(errorMessage));
            })
    }
}