import { createSelector } from "reselect";

export const selectOmniaVerseGames = state => state.games.omniaverseGames;
export const selectActionAdventureGames = state => state.games.actionAdventureGames;
export const selectAnimationGames = state => state.games.animationGames;
export const selectStrategyGames = state => state.games.strategyGames;
export const selectHorrorGames = state => state.games.horrorGames;
export const selectRacingGames = state => state.games.racingGames;
export const selectFamilyGames = state => state.games.familyGames;
export const selectKidsGames = state => state.games.kidsGames;
export const selectSciFiFantasyGames = state => state.games.sciFiFantasyGames;
export const selectTrendingGames = state => state.games.trendingGames;

export const selectOmniaVerseGamesSelector = createSelector(
    [selectOmniaVerseGames],
    omniaverseGames => omniaverseGames.data
)

export const selectActionAdventureGamesSelector = createSelector(
    [selectActionAdventureGames],
    actionAdventureGames => actionAdventureGames.data
)

export const selectAnimationGamesSelector = createSelector(
    [selectAnimationGames],
    animationGames => animationGames.data
)

export const selectStrategyGamesSelector = createSelector(
    [selectStrategyGames],
    strategyGames => strategyGames.data
)

export const selectHorrorGamesSelector = createSelector(
    [selectHorrorGames],
    horrorGames => horrorGames.data
)

export const selectRacingGamesSelector = createSelector(
    [selectRacingGames],
    racingGames => racingGames.data
)

export const selectFamilyGamesSelector = createSelector(
    [selectFamilyGames],
    familyGames => familyGames.data
)

export const selectKidsGamesSelector = createSelector(
    [selectKidsGames],
    kidsGames => kidsGames.data
)

export const selectSciFiFantasyGamesSelector = createSelector(
    [selectSciFiFantasyGames],
    sciFiFantasyGames => sciFiFantasyGames.data
)

export const selectTrendingGamesSelector = createSelector(
    [selectTrendingGames],
    trendingGames => trendingGames.data
)