import { favouritesActionTypes } from "./favourites.types"
import { addToFavouritesUtil, removeFromFavouritesUtil, userFavouritesUtil } from "./favourites.utils"

const initialState = {
    favourites: {
      collectibles: [],
      games: []
    },
    userId: ""
  }

const favouritesReducer = async (state = initialState, action) => {
    switch (action.type) {
        case favouritesActionTypes.ADD_TO_FAVOURITES:
            return {
                ...state,
                favouritesList: addToFavouritesUtil(state, action.payload)
            }
        case favouritesActionTypes.REMOVE_FROM_FAVOURITES:
            return {
                ...state,
                favouritesList: removeFromFavouritesUtil(state, action.payload)
            }
        case favouritesActionTypes.GET_ALL_FAVOURITES:
            return {
                ...state,
                favouritesList: await userFavouritesUtil(state, action.payload)
            }            
        default:
            return state
    }
}

export default favouritesReducer
